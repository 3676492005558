module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteURL":"https://www.clearlinkconsulting.com"},
    },{
      plugin: require('../../../node_modules/@leshen/gatsby-theme-leshen/gatsby-browser.js'),
      options: {"plugins":[],"analyticsId":"GTM-MDRKB24P","brandTokens":["TEL"],"mapiBrandToken":"TEL","siteName":"clearlinkconsulting","alternateName":"clearlinkconsulting.com","siteURL":"https://www.clearlinkconsulting.com","defaultTitleTemplate":"","defaultPhone":"8336110579","phoneSymbol":"-","defaultPromoCode":"171126","trailingSlash":"never","smartyStreetsWebsiteKey":"","addressQualificationCode":"","convertProjectID":"10046086","vwoProjectID":"894940"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"business-providers","short_name":"business-providers","start_url":"/","background_color":"#1990FF","theme_color":"#1990FF","icon":"src/images/favicon.png","display":"browser","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"720a2df4463608d46cc3db726eb043b5"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
