import * as React from 'react'
import { css, Global, withTheme } from '@emotion/react'

const GlobalCss = withTheme(() => (
  <Global
    styles={css`
      li {
        svg {
          fill: #48d597;
        }
      }

      h1,
      h2,
      h3 {
        font-weight: 300 !important;
      }

      h4,
      h5,
      h6 {
        font-weight: 500 !important;
      }

      body {
        font-weight: 400;
      }

      button {
        font-weight: 500 !important;
      }

      .top-bar {
        .top-nav {
          a {
            border-left: 1px solid #1d2329;
            &:first-of-type {
              border-left: none;
            }
          }
        }
      }

      .show-desktop,
      .show-mobile {
        display: none;
      }

      @media (min-width: 876px) {
        .show-desktop {
          display: block;
        }
      }

      @media (max-width: 876px) {
        .show-mobile {
          display: block;
        }
      }

      button[color='primary']:hover {
        background-color: #0f5cbe;
      }

      li {
        svg {
          fill: #3773b6;
        }
      }
    `}
  />
))

export default GlobalCss
